import classNames from 'clsx';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import Pager from 'components/tables/Pager';
import { parseISO } from 'date-fns';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as CreateIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as AddIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as CloseIcon } from 'img/icons/icon-padlock.svg';
import { ReactComponent as OpenIcon } from 'img/icons/icon-unlock.svg';
import { ReactComponent as DetailIcon } from 'img/icons/icon-file.svg';

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { makeLink } from 'services/localPaths';
import {
  PATH_ORGANIZATIONS_ACCOUNTS_ADD,
  PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
} from 'services/pathConstants';
import { t } from 'services/utils/translation';
import {
  PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
} from '../../../../services/pathConstants';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import useClearFilters from 'hooks/useClearFilters';
import styles from './index.module.scss';
import { IUseGetList, useGetList } from '../../../../hooks/useGetList';
import {
  deleteBillingAccountApiV2,
  exportBillingAccountApiV2,
} from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';
import HeaderWithDatePickerRangeV2 from 'components/tables/HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';
import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import {
  IBillingAccount,
  IBillingAccountComment,
  IBillingAccountForList,
} from '../../../../services/api/organizationsV2/billing-account/IBillingAccount';
import { composeClientSelectOptionsInNumbersArray } from '../../../../services/utils/selects/composeIClientSelectOptionsInNumbers';
import { IGetPaginatedBillingAccountsParams } from '../../../../services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';
import useModalV2 from '../../../../hooks/useModalV2';
import { IModal } from '../../../../hooks/useModal';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import useAddOrEditPushNavigation from 'hooks/useAddOrEditPushNavigation';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import { getForSelectBillingAccountTypeApiV2 } from 'services/api/organizationsV2/billing-account-type/billingAccountTypaApi';
import { DropdownText } from '../../../../components/tables/DropdownText/DropdownText';
import TDCollapse, {
  toggleList,
} from 'components/tables/TdCollapse/TDCollapse';
import SubHeaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import { initialBillingAccountsFilters } from '../../../../store/initialStore/initialBillingAccountsFilters';
import useBillingAccountHandleSearch from './useBillingAccountHandleSearch';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import { getPaginatedBillingAccountsV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { getForSelectCounterpartyApiV2 } from '../../../../services/api/organizationsV2/counterparty/counterpartyApi';
import {
  billingAccountColumnVisibilityInitial,
  billingAccountFilters,
} from './columnLabelsBillingAccounts';
import { extractCounterpartyName } from 'services/utils/bilingAccountUtils/billingAccountUtils';
import DoubleHeaderFilterContainer from 'components/tables/DoubleHeaderFilterContainer/DoubleHeaderFilterContainer';
import { formatDateTimeShort } from '../../../../services/utils/dateHelper/dateHelper';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import {
  extractGetLeftValueIfTrue,
  getDropDownEditTitle,
} from '../../../../services/utils/extractGetLeftValueIfTrue';
import TDSubtitle from 'components/tables/TDSubtitle/TDSubtitle';
import useNextWindow from 'hooks/useNextWindow';
import { COLUMN_LABELS_BILLING_ACCOUNT } from './columnLabelsBillingAccounts';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import useBillingAccountToClosed from '../../../../hooks/useBillingAccountToClosed/useBillingAccountToClosed';
import IBillingAccountClosedParams from 'services/api/organizationsV2/billing-account/IBillingAccountClosedParams';
import THCustom from 'components/tables/THCustom/THCustom';
import useGetInitialBillingAccountFilters from './hooks/useGetInitialBillingAccountFilters';
import {
  THCustomDate,
  THCustomDefault,
  THCustomSelect,
} from 'components/tables/THCustomWrapper/THCustomWrapper';
import useSaveBillingAccountFilters from './hooks/useSaveBillingAccountFilters';
import useClearBillingAccountFilters from './hooks/useClearBillingAccountFilters';
import useGetKeyForMyFilter from 'hooks/useGetKeyForMyFilter/useGetKeyForMyFilter';
import MyFilters from 'components/tables/MyFilters/MyFilters';
import useGetBillingAccountAccessPolicy from './hooks/useGetBillingAccountAccessPolicy';

const deleteMessageError = t('Не удалось удалить лицевой счет');

const BILLING_ACCOUNT_CLOSED_HINT = t('Событие закрыто');
const BILLING_ACCOUNT_IS_OPEN_HINT = t('Событие открыто');

const OPEN_TEXT_DROPDOWN_ITEM = t('Открыть лицевой счет');
const CLOSE_TEXT_DROPDOWN_ITEM = t('Закрыть лицевой счет');

const getDropdownCloseText = extractGetLeftValueIfTrue(
  OPEN_TEXT_DROPDOWN_ITEM,
  CLOSE_TEXT_DROPDOWN_ITEM
);

const CREATE_BUTTON_TITLE = t('Добавить счет');
const MODAL_DELETE_TITLE = t('Удалить лицевой счет');

const convertData = (payload: IBillingAccount[]): IBillingAccountForList[] =>
  payload.map((billingAccount: IBillingAccount): IBillingAccountForList => {
    const {
      billing_account_datetime_added: dateAdded,
      billing_account_datetime_edited: dateEdited,
      billing_account_pk: pk,
      billing_account_list_of_comments: comments,
      billing_account_counterparty: counterparty,
    } = billingAccount;

    return {
      ...billingAccount,
      counterpartyLink: (
        <Link
          to={makeLink(
            PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
            counterparty.counterparty_pk
          )}
        >
          {extractCounterpartyName(counterparty)}
        </Link>
      ),
      openList: false,
      createDate: formatDateTimeShort(parseISO(dateAdded)),
      editedDate: dateEdited ? formatDateTimeShort(parseISO(dateEdited)) : '',
      billingAccountLink: makeLink(PATH_ORGANIZATIONS_ACCOUNTS_VIEW, pk),
      commentElements: comments
        ?.reverse()
        .map(
          (comment: IBillingAccountComment): JSX.Element => (
            <DropdownText
              str={comment.billing_account_comment_text}
              author={
                comment?.billing_account_comment_author?.full_name ??
                t('Отправитель неизвестен')
              }
            />
          )
        ),
    };
  });

const composeParamsForFetchGetBillingAccountsApi = (
  params: IBillingAccountFilters
): IGetPaginatedBillingAccountsParams => {
  const {
    billing_account_type_fk_array,
    billing_account_counterparty_fk_array,
  } = composeClientSelectOptionsInNumbersArray<IBillingAccountFilters>(params, [
    'billing_account_type_fk_array',
    'billing_account_counterparty_fk_array',
  ]);
  return deleteUnnecessaryParameters(
    {
      ...params,
      billing_account_type_fk_array,
      billing_account_counterparty_fk_array,
      billing_account_is_closed: extractValueFromClientSelectOptionV2(
        params?.billing_account_is_closed
      ),
    },
    ['isActive1CNumberFilter']
  );
};

type BillingAccountPagePropsType = { counterpartyPk?: number };

const BillingAccountPage: FC<BillingAccountPagePropsType> = ({
  counterpartyPk,
}) => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(billingAccountColumnVisibilityInitial);

  const {
    billingAccountTypeHaveAccessToRead,
    counterpartyHaveAccessToRead,
    billingAccount: {
      haveAccessToCreate,
      haveAccessToUpdate,
      haveAccessToDelete,
      haveAccessToExport,
    },
  } = useGetBillingAccountAccessPolicy();

  const isInsidePage = !!counterpartyPk;

  const saveFilters = useSaveBillingAccountFilters(counterpartyPk);

  const onFinally = () => setClearingFiltersSpinner(false);

  const initialParams = useGetInitialBillingAccountFilters(counterpartyPk);

  const {
    data: billingAccounts,
    isLoading,
    total,
    onSearchRequest,
    onSortClick,
    refreshListData,
    setStart,
    setLength,
    setParams,
    setData,
    refreshAndReturnToStartPosition,
    params: {
      length,
      skip,
      billing_account_code_number: filterBillingAccountNumber,
      billing_account_datetime_added_upper_bound: filterAddedUpper,
      billing_account_datetime_added_lower_bound: filterAddedLower,
      billing_account_datetime_edited_upper_bound: filterEditedUpper,
      billing_account_datetime_edited_lower_bound: filterEditedLower,
      billing_account_description: filterDescription,
      billing_account_type_fk_array: filterTypes,
      billing_account_comment_text: filterComment,
      billing_account_counterparty_fk_array: filterCounterparty,
      billing_account_stek_number: filterStekNumber,
      billing_account_1c_number: filter1CNumber,
      billing_account_address: filterAddress,
      isActive1CNumberFilter,
      billing_account_is_closed: filterIsClosed,
    },
    params,
  }: IUseGetList<IBillingAccountForList[], IBillingAccountFilters> = useGetList(
    {
      getDataApi: getPaginatedBillingAccountsV2,
      initialParams,
      saveFilters,
      onFinally,
      convertData,
      convertedParameters: composeParamsForFetchGetBillingAccountsApi,
    }
  );

  const HINT_FOR_1C_FILTER = `${COLUMN_LABELS_BILLING_ACCOUNT.NUMBER_1C}: ${filter1CNumber}`;
  const HINT_FOR_STEC_FILTER = `${COLUMN_LABELS_BILLING_ACCOUNT.NUMBER_STEK}: ${filterStekNumber}`;

  //SEARCH

  const {
    handleSearchCodeNumber,
    handleSearchDescription,
    handleSearchTypeFkArray,
    handleSearchAddress,
    handleSearchCounterpartyFkArray,
    handleSearchComment,
    handleSearchSTECNumber,
    handleSearch1CNumber,
    handleIsActive1CNumber,
  } = useBillingAccountHandleSearch(onSearchRequest);

  const { handleSelectBooleanType, booleanOptions } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.billingAccount,
  });

  //NAVIGATION CONTROL

  const openBillingAccountViewPage = useNextWindow(
    PATH_ORGANIZATIONS_ACCOUNTS_VIEW
  );

  const toggleCommentList = toggleList(setData, 'billing_account_pk');

  const clearBillingAccountFilters =
    useClearBillingAccountFilters(counterpartyPk);

  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(
      initialBillingAccountsFilters,
      params,
      clearBillingAccountFilters,
      setParams,
      { billing_account_counterparty_fk: counterpartyPk }
    );

  const {
    state: {
      isAddEditFormOpen,
      isDeleteFormOpen,
      entity: targetBillingAccount,
    },
    openAddEditForm,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteBillingAccountApiV2,
    entityIdKey: 'pk',
  });

  useAddOrEditPushNavigation({
    pk: targetBillingAccount?.pk,
    isAddEditFormOpen,
    pathEdit: PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
    pathAdd: PATH_ORGANIZATIONS_ACCOUNTS_ADD,
    state: { counterpartyPk },
  });

  const closeHandler = useBillingAccountToClosed();

  const doCloseOrOpenEvent = async (params: IBillingAccountClosedParams) => {
    await closeHandler(params);
    refreshListData();
  };

  const keyMyFilter = useGetKeyForMyFilter({
    counterpartyPk,
    filterType: 'billingAccount',
  });

  return (
    <MainLayout isInWrapper={isInsidePage}>
      <Subheader viewBackLocalPatch={!isInsidePage}>
        <div style={{ display: 'flex' }} className="mr-5">
          <SubheaderButton>
            <MyFilters
              setFilters={setParams}
              currentFilter={params}
              keyMyFilter={keyMyFilter}
              saveFilters={saveFilters}
            />
          </SubheaderButton>
          <SubheaderButton
            onClick={() => clearFilters()}
            bordered={true}
            disabled={isDisableClearButton}
          >
            {UI_TITLE.REMOVE_FILTERS}
          </SubheaderButton>
          <RefreshButton
            toggleRefresh={refreshAndReturnToStartPosition}
            isLoading={isLoading}
          />
          <SubHeaderButtonExport
            params={params}
            getExportLinkApi={exportBillingAccountApiV2}
            disabled={isLoading}
            convertParams={composeParamsForFetchGetBillingAccountsApi}
            haveAccessToExport={haveAccessToExport}
          />
        </div>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <CreateIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <THCustomDefault
                className={classNames(styles.thNumber)}
                isVisible={visibility.number}
              >
                <HeaderWithSearchAndSort
                  field="billing_account_code_number"
                  title={COLUMN_LABELS_BILLING_ACCOUNT.NUMBER}
                  onSearch={handleSearchCodeNumber}
                  onSort={onSortClick}
                  defaultValue={
                    filterBillingAccountNumber
                      ? filterBillingAccountNumber + ''
                      : ''
                  }
                />
              </THCustomDefault>
              <THCustomDate
                className={styles.thDateTimeAdded}
                isVisible={visibility.datetime_added}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_BILLING_ACCOUNT.DATETIME_ADDED}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'billing_account_datetime_added_lower_bound',
                      'billing_account_datetime_added_upper_bound',
                    ],
                  }}
                  startDate={
                    filterAddedLower ? new Date(filterAddedLower) : null
                  }
                  endDate={filterAddedUpper ? new Date(filterAddedUpper) : null}
                  field="billing_acx`count_datetime_added"
                  onSort={onSortClick}
                  hasTime
                />
              </THCustomDate>
              <THCustomDate
                isVisible={visibility.datetime_edited}
                className={`p-0 ${!visibility.datetime_edited ? 'd-none' : ''}`}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_BILLING_ACCOUNT.DATETIME_EDITED}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'billing_account_datetime_edited_lower_bound',
                      'billing_account_datetime_edited_upper_bound',
                    ],
                  }}
                  startDate={
                    filterEditedLower ? new Date(filterEditedLower) : null
                  }
                  endDate={
                    filterEditedUpper ? new Date(filterEditedUpper) : null
                  }
                  field="billing_account_datetime_edited"
                  onSort={onSortClick}
                  hasTime
                />
              </THCustomDate>
              <THCustom isVisible={visibility.description}>
                <HeaderWithSearch
                  title={COLUMN_LABELS_BILLING_ACCOUNT.DESCRIPTION}
                  onSearch={handleSearchDescription}
                  defaultValue={filterDescription}
                />
              </THCustom>
              <THCustomSelect isVisible={visibility.type}>
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectBillingAccountTypeApiV2}
                  id="billing_account_type"
                  title={COLUMN_LABELS_BILLING_ACCOUNT.TYPE}
                  isMulti={true}
                  options={filterTypes}
                  onChange={handleSearchTypeFkArray}
                  haveReadPermission={billingAccountTypeHaveAccessToRead}
                />
              </THCustomSelect>
              <THCustomDefault isVisible={visibility.address}>
                <HeaderWithSearchAndSort
                  field="billing_account_address"
                  title={COLUMN_LABELS_BILLING_ACCOUNT.ADDRESS}
                  onSearch={handleSearchAddress}
                  onSort={onSortClick}
                  defaultValue={filterAddress}
                />
              </THCustomDefault>
              <THCustomSelect
                isVisible={visibility.counterparty && !counterpartyPk}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  id="billing_account_counterparty_fk_array"
                  title={COLUMN_LABELS_BILLING_ACCOUNT.COUNTERPARTY}
                  selectHandler={getForSelectCounterpartyApiV2}
                  isMulti={true}
                  options={filterCounterparty}
                  onChange={handleSearchCounterpartyFkArray}
                  haveReadPermission={counterpartyHaveAccessToRead}
                />
              </THCustomSelect>
              <THCustomSelect
                isVisible={visibility.stek_number_and_1c_number}
                className={styles.thStekAnd1cNumber}
              >
                <DoubleHeaderFilterContainer
                  isFirstIsVisibleProp={isActive1CNumberFilter}
                  saveIsActive={handleIsActive1CNumber}
                  componentSettings={{
                    first: {
                      value: filter1CNumber,
                      hint: HINT_FOR_1C_FILTER,
                      name: 'filter1CNumber',
                    },
                    second: {
                      value: filterStekNumber,
                      hint: HINT_FOR_STEC_FILTER,
                      name: 'filterStekNumber',
                    },
                  }}
                >
                  <HeaderWithSearch
                    title={COLUMN_LABELS_BILLING_ACCOUNT.NUMBER_1C}
                    onSearch={handleSearch1CNumber}
                    defaultValue={filter1CNumber}
                  />
                  <HeaderWithSearch
                    title={COLUMN_LABELS_BILLING_ACCOUNT.NUMBER_STEK}
                    onSearch={handleSearchSTECNumber}
                    defaultValue={filterStekNumber}
                  />
                </DoubleHeaderFilterContainer>
              </THCustomSelect>
              <THCustomSelect
                isVisible={visibility.isClosed}
                className={classNames(
                  styles.columnEventIsClosed,
                  styles.thIsClosed
                )}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_BILLING_ACCOUNT.IS_CLOSED}
                  options={booleanOptions}
                  onChange={handleSelectBooleanType}
                  defaultValue={filterIsClosed}
                />
              </THCustomSelect>
              <THCustomDefault isVisible={visibility.comment}>
                <HeaderWithSearch
                  title={COLUMN_LABELS_BILLING_ACCOUNT.COMMENT}
                  onSearch={handleSearchComment}
                  defaultValue={filterComment}
                />
              </THCustomDefault>
              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {billingAccountFilters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {billingAccounts &&
                billingAccounts.map(
                  ({
                    billing_account_code_number: number,
                    billing_account_pk: pk,
                    createDate,
                    editedDate,
                    billingAccountLink,
                    billing_account_description: description,
                    billing_account_type: type,
                    billing_account_address: address,
                    counterpartyLink,
                    billing_account_1c_number: number1C,
                    billing_account_stek_number: numberStec,
                    commentElements,
                    billing_account_is_closed: isClosed,
                    openList,
                  }: IBillingAccountForList) => {
                    return (
                      <tr key={pk}>
                        <td
                          className={classNames({
                            'd-none': !visibility.number,
                          })}
                        >
                          <Link
                            className="d-block"
                            style={{ maxWidth: '100%' }}
                            to={billingAccountLink}
                          >
                            {number}
                          </Link>
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.datetime_added,
                          })}
                        >
                          <span>{createDate}</span>
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.datetime_edited,
                          })}
                        >
                          <span>{editedDate}</span>
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.description,
                          })}
                        >
                          <span>{description}</span>
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.type,
                          })}
                        >
                          {type.billing_account_type_rendition}
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.address,
                          })}
                        >
                          <span>{address}</span>
                        </td>

                        <td
                          className={classNames({
                            'd-none':
                              !visibility.counterparty || !!counterpartyPk,
                          })}
                        >
                          {counterpartyLink}
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.stek_number_and_1c_number,
                          })}
                        >
                          {number1C && (
                            <>
                              <TDSubtitle>{t('1C')}: </TDSubtitle>
                              <span>{number1C}</span>
                              <br />
                            </>
                          )}
                          {numberStec && (
                            <>
                              <TDSubtitle>{t('СТЕК')}: </TDSubtitle>
                              <span>{numberStec}</span>
                              <br />
                            </>
                          )}
                        </td>

                        <td
                          className={classNames({
                            'd-none': !visibility.isClosed,
                          })}
                        >
                          <TDCheckBox
                            isCheck={isClosed}
                            hint={
                              isClosed
                                ? BILLING_ACCOUNT_CLOSED_HINT
                                : BILLING_ACCOUNT_IS_OPEN_HINT
                            }
                            id={`isClosed_${pk}`}
                          />
                        </td>

                        {commentElements ? (
                          <TDCollapse
                            visibility={visibility.comment}
                            openList={openList}
                            entity={commentElements}
                            toggleTd={toggleCommentList}
                            entityId={pk}
                          />
                        ) : (
                          <td></td>
                        )}
                        <td align="right">
                          <ActionsDropdown>
                            <ActionsDropdownItem
                              label={UI_TITLE.DETAIL}
                              onClick={openBillingAccountViewPage.bind(
                                null,
                                pk
                              )}
                              icon={<DetailIcon className="text-primary" />}
                            />

                            <ActionsDropdownItem
                              label={getDropDownEditTitle(haveAccessToUpdate)}
                              onClick={openAddEditForm.bind(null, {
                                pk,
                                rendition: number,
                              })}
                              icon={<AddIcon className="text-primary" />}
                            />
                            {haveAccessToUpdate && (
                              <ActionsDropdownItem
                                label={getDropdownCloseText(isClosed)}
                                onClick={doCloseOrOpenEvent.bind(null, {
                                  pk,
                                  billing_account_is_closed: !isClosed,
                                  billing_account_address: address,
                                })}
                                icon={
                                  isClosed ? (
                                    <OpenIcon className="text-primary" />
                                  ) : (
                                    <CloseIcon className="text-primary" />
                                  )
                                }
                              />
                            )}
                            {haveAccessToDelete && (
                              <ActionsDropdownItem
                                label={UI_TITLE.DELETE}
                                onClick={toggleDeleteForm.bind(null, {
                                  pk: pk,
                                  rendition: number,
                                })}
                                icon={<DeleteIcon className="text-primary" />}
                              />
                            )}
                          </ActionsDropdown>
                        </td>
                      </tr>
                    );
                  }
                )}
              {!isLoading && billingAccounts?.length === 0 && (
                <tr>
                  <td colSpan={10}>{t('Лицевые счета отсутствуют')}</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
        {!isLoading && (
          <Pager
            onPageChange={setStart}
            onLengthChange={setLength}
            total={total}
            length={length}
            start={skip}
            saveFilters={saveFilters}
          />
        )}
        {targetBillingAccount?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetBillingAccount?.pk}
            entityName={targetBillingAccount?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}

        {isLoading && <LoaderFetch />}
      </div>
    </MainLayout>
  );
};

export default BillingAccountPage;
