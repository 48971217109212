import { useCallback } from 'react';
import { IFormValuesTask } from '../TaskForm';
import { useDispatch } from 'react-redux';
import Task from 'models/Task';
import { FormikState } from 'formik';
import { saveTaskCurrentValues, setInitialNewTask } from 'store/task/actions';

//TODO:
/** мы не можем типизировать setValues, потому что имеем проблему с компонентом SelectField
 для taskType где мы подставляем [], для того чтобы сбросить value, потому что если мы подставляем undefined
 value не сбрасывается - с этим надо разобраться**/

const resetToDefault = (newTask: Task, setValues: Function) => {
  const defaultTask: IFormValuesTask = {
    ...newTask,
    internal_task_description: '',
    taskType: undefined,
    internal_task_time_adjustment: undefined,
    internal_task_new_comment: '',
  };
  setValues(defaultTask);
};

const useExtractRefundValue = (
  newTask: Task,
  taskPk?: number,
  actualTask?: IFormValuesTask | null
) => {
  const isNew = ((pk?: number): pk is undefined => {
    return !pk;
  })(taskPk);

  const dispatchRedux = useDispatch();

  return useCallback(
    (
      resetForm: (
        nextState?: Partial<FormikState<IFormValuesTask | Task>> | undefined
      ) => void,
      setValues: (values: IFormValuesTask | Task) => void
    ) => {
      return () => {
        if (isNew) {
          dispatchRedux(setInitialNewTask());
          resetToDefault(newTask, setValues);
        } else if (actualTask) {
          dispatchRedux(saveTaskCurrentValues(taskPk + '')(actualTask));
          resetForm(actualTask);
        }
      };
    },
    [actualTask, dispatchRedux, isNew, newTask, taskPk]
  );
};

export default useExtractRefundValue;
