import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
 import {
  getCurrentDateToClientDate,
  isDateInPast,
} from 'services/utils/dateHelper/dateHelper';

// Функция для проверки и обновления internal_task_date_duedate
const updateTaskDueDateIfPast = (task: IFormValuesTask): IFormValuesTask => {
  const taskWithCurrentDate = {
    ...task,
    internal_task_date_duedate: getCurrentDateToClientDate(),
  };

  const dateDuedate = task?.internal_task_date_duedate;

  if (!dateDuedate) {
    console.error('Invalid date provided', dateDuedate);
    return taskWithCurrentDate;
  }

  if (
    task?.internal_task_date_duedate &&
    isDateInPast(task.internal_task_date_duedate)
  ) {
    return taskWithCurrentDate;
  }
  return task;
};

export default updateTaskDueDateIfPast;
