import { IFormValuesTask } from "components/tasks/TaskForm/TaskForm";
import IClientSelectOptionV2 from "services/api/interfacesApi/IClientSelectOptionV2";

function taskValuesMergeWithStatus(
  current: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask;

function taskValuesMergeWithStatus(
  current?: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask | undefined;

function taskValuesMergeWithStatus(
  current?: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask | undefined {
  if (!current) {
    return undefined;
  }

  return current?.status?.value ? current : { ...current, status: statusDefault };
}

export default taskValuesMergeWithStatus;