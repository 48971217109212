import Task from 'models/Task';
import { IFormValuesTask } from '../../TaskForm';
import { useSelector } from 'react-redux';
import {
  getDefaultStatusFromStore,
  getTaskFormValuesFromStore,
} from 'store/task/selectors';
import { NEW_TASK_KEY } from '../../constants/NEW_TASK';
import { useMemo } from 'react';
import updateTaskDueDateIfPast from './utils/updateTaskDueDateIfPast';
import taskValuesMergeWithStatus from './utils/taskValuesMergeWithStatus';
import getTaskValuesForDefaultAccountOrCounterparty from './utils/getTaskValuesForDefaultAccountOrCounterparty';

type UseGetTaskDefaultValuesProps = {
  taskPk?: number;
  initialValuesFromOrder?: IFormValuesTask;
  initialValuesFromParentTask?: IFormValuesTask | null;
  newTask: IFormValuesTask | Task;
  isDefaultAccountOrCounterparty: boolean;
};

const useGetTaskDefaultValues = ({
  taskPk,
  newTask,
  initialValuesFromOrder,
  initialValuesFromParentTask,
  isDefaultAccountOrCounterparty,
}: UseGetTaskDefaultValuesProps): IFormValuesTask | Task => {
  const formTaskValues = useSelector(getTaskFormValuesFromStore);
  const taskId = taskPk ?? NEW_TASK_KEY;
  const defaultStatus = useSelector(getDefaultStatusFromStore);

  const { current, initial } = formTaskValues?.[taskId] || {};

  return useMemo(() => {
    if (initialValuesFromParentTask) {
      return taskValuesMergeWithStatus(
        initialValuesFromParentTask,
        defaultStatus
      );
    }

    if (initialValuesFromOrder) {
      return taskValuesMergeWithStatus(initialValuesFromOrder, defaultStatus);
    }

    if (isDefaultAccountOrCounterparty) {
      return getTaskValuesForDefaultAccountOrCounterparty(
        newTask,
        defaultStatus,
        current
      );
    }

    if (taskId === NEW_TASK_KEY && current) {
      const mergeNewTaskWithStatus = taskValuesMergeWithStatus(
        current,
        defaultStatus
      );

      return updateTaskDueDateIfPast(mergeNewTaskWithStatus);
    }
    if (taskId === NEW_TASK_KEY && initial) {
      const mergeNewTaskWithStatus = taskValuesMergeWithStatus(
        initial,
        defaultStatus
      );
      return updateTaskDueDateIfPast(mergeNewTaskWithStatus);
    }
    return (
      taskValuesMergeWithStatus(current, defaultStatus) || initial || newTask
    );
  }, [
    current,
    defaultStatus,
    initial,
    initialValuesFromOrder,
    initialValuesFromParentTask,
    isDefaultAccountOrCounterparty,
    newTask,
    taskId,
  ]);
};

export default useGetTaskDefaultValues;
