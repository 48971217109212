import { memo, FC } from 'react';
import { PaginationItem, PaginationLink } from 'reactstrap';
import styles from './index.module.scss';
import classNames from 'clsx';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';

type PaginationCellProps = {
  item: number;
  isActive: boolean;
  ochangePage: (
    event: React.MouseEvent<HTMLAnchorElement>,
    page?: number
  ) => void;
};

const PaginationCell: FC<PaginationCellProps> = ({
  item,
  isActive,
  ochangePage,
}) => {
  const newItem = item;
  const cellWidth = `${newItem.toString().length * 12}px`; // Пример: 1411 -> 48px
  return (
    <PaginationItem key={item} active={isActive} id={`id-${item}`}>
      <PaginationLink
        href="#"
        onClick={(e) => {
          ochangePage(e, item);
        }}
        className={classNames(styles.paginationLink, 'p-1', {
          [styles.active]: isActive,
        })}
        style={{ minWidth: cellWidth }}
      >
        {newItem}
      </PaginationLink>
    </PaginationItem>
  );
};

export default memo(PaginationCell);
