import { SELECTED_COUNTERPARTY_IN_TASK } from 'components/tasks/TaskForm/constants/selectedCounterparty';
import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import Task from 'models/Task';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import taskValuesMergeWithStatus from './taskValuesMergeWithStatus';

const getTaskValuesForDefaultAccountOrCounterparty = (
  newTask: IFormValuesTask | Task,
  defaultStatus?: IClientSelectOptionV2,
  current?: IFormValuesTask
) => {
  const mergedValues = {
    ...current,
    selectedCounterparty: newTask?.counterparty
      ? SELECTED_COUNTERPARTY_IN_TASK.selectedCounterparty
      : SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount,
    account: newTask?.account,
    counterparty: newTask?.counterparty,
  };

  return taskValuesMergeWithStatus(mergedValues, defaultStatus);
};


export default getTaskValuesForDefaultAccountOrCounterparty