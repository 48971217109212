import { MouseEvent, useCallback } from 'react';

export enum PagerAction {
  first = 'first',
  previous = 'previous',
  next = 'next',
  last = 'last',
  number = 'number',
}

type PropsTypeUsePagerHandlers = {
  onPageChange: (skip: number) => void; // Функция для изменения страницы
  saveFilters?: (filters: any) => void; // Опциональная функция для сохранения фильтров
  total: number;
  start: number;
  length: number;
  currentPage: number;
  totalPages: number;
};

const keyStartIndex = 'skip';

type ActionHandlerProps = {
  length: number;
  onPageChange: (skip: number) => void;
  saveFilters?: (filters: any) => void;
  currentPage?: number;
  totalPages?: number;
};

const ACTION_MAP: Record<
  PagerAction,
  (props: ActionHandlerProps, page?: number) => void
> = {
  // Обработчик для перехода на первую страницу
  [PagerAction.first]: ({ length, onPageChange, saveFilters }) => {
    const skipFirst = 0; // Пропускаем 0 записей (первая страница)
    onPageChange(skipFirst);
    saveFilters?.({ [keyStartIndex]: skipFirst });
  },

  // Обработчик для перехода на предыдущую страницу
  [PagerAction.previous]: ({
    length,
    currentPage,
    onPageChange,
    saveFilters,
  }) => {
    const skipPrevious = (currentPage! - 1) * length - length;
    onPageChange(skipPrevious);
    saveFilters?.({ [keyStartIndex]: skipPrevious });
  },

  // Обработчик для перехода на следующую страницу
  [PagerAction.next]: ({ length, currentPage, onPageChange, saveFilters }) => {
    const skipNext = currentPage! * length;
    onPageChange(skipNext);
    saveFilters?.({ [keyStartIndex]: skipNext });
  },

  // Обработчик для перехода на последнюю страницу
  [PagerAction.last]: ({ length, totalPages, onPageChange, saveFilters }) => {
    const skipLast = (totalPages! - 1) * length;
    onPageChange(skipLast);
    saveFilters?.({ [keyStartIndex]: skipLast });
  },

  // Обработчик для перехода на конкретную страницу
  [PagerAction.number]: ({ length, onPageChange, saveFilters }, page) => {
    if (page === undefined) {
      return;
    }
    const skipNumber = (page - 1) * length;
    onPageChange(skipNumber);
    saveFilters?.({ [keyStartIndex]: skipNumber });
  },
};

const usePagerHandlers = (props: PropsTypeUsePagerHandlers) => {
  const { onPageChange, length, currentPage, totalPages, saveFilters } = props;

  const changePage = useCallback(
    (action: PagerAction, page?: number) => {
      const handler = ACTION_MAP[action];
      if (handler) {
        handler(
          { length, onPageChange, saveFilters, currentPage, totalPages },
          page
        );
      }
    },
    [onPageChange, saveFilters, currentPage, length, totalPages]
  );

  return useCallback(
    (action: PagerAction) =>
      (event: MouseEvent<HTMLAnchorElement>, page?: number) => {
        event.preventDefault();
        changePage(action, page);
      },
    [changePage]
  );
};

export default usePagerHandlers;
